<template>
  <div class="question-page flex flex-column flex-1">
    <div class="question-page__header flex flex-column">
      <div class="question-page__legend">
        Вопрос {{ currentQuestion }} / <span>{{ questionsCount }}</span>
      </div>
      <div class="question-page__progress">
        <div class="progress">
          <div
            :style="`width: ${(currentQuestion / questionsCount) * 100}%;`"
            class="progress-bar"
            role="progressbar"
            :aria-valuenow="currentQuestion"
            :aria-valuemin="1"
            :aria-valuemax="questionsCount"
          />
        </div>
      </div>
    </div>
    <div
      class="
        question-page__body
        flex-1 flex flex-1
        justify-content-center
        question-page__body
        align-item-start
      "
    >
      <tabs-line-questions
        @updateAnswers="updateAnswers"
        v-if="questionGroups && type === 'list'"
        :questionGroup="questionGroups[currentQuestion - 1]"
        :missedGroup="missedGroup"
      />

      <tabs-questions
        @updateAnswers="updateAnswers"
        v-if="questionGroups && type === 'tabs'"
        :questionGroup="questionGroups[currentQuestion - 1]"
        :missedGroup="missedGroup"
      />

      <table-question
        @updateAnswers="updateAnswers"
        v-if="questionGroups && type === 'table'"
        :questionGroup="questionGroups[currentQuestion - 1]"
        :missedGroup="missedGroup"
      />

      <icons-question
        @updateAnswers="updateAnswers"
        v-if="questionGroups && type === 'icons'"
        :questionGroup="questionGroups[currentQuestion - 1]"
        :missedGroup="missedGroup"
      />
    </div>
    <div
      class="
        question-page__footer
        flex
        justify-content-between
        align-item
        center
      "
    >
      <button @click="prevPage" class="btn btn-w">Назад</button>
      <button @click="nextPage" :disabled="isRequestPending" class="btn btn-accent">Дальше</button>
    </div>
  </div>
</template>

<script>
import TabsLineQuestions from "./TabsLineQuestions.vue";
import TabsQuestions from "./TabsQuestions.vue";
import TableQuestion from "./TableQuestion.vue";
import { notify } from "@kyvg/vue3-notification";
import { toRef } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import IconsQuestion from "./IconsQuestion.vue";
export default {
  components: {
    TabsLineQuestions,
    TabsQuestions,
    TableQuestion,
    IconsQuestion,
  },
  name: "question-template",
  props: {
    currentQuestion: {
      type: Number,
      default: 1,
    },
    questionsCount: {
      type: Number,
      default: 1,
    },
    questionGroups: {
      type: Array,
    },
    isRequestPending: {
      type: Boolean,
      default: false,
    },
    missedGroup: {
      type: Number,
      default: 0,
    }
  },
  setup(props, context) {
    let answers = {};
    let groups = toRef(props, "questionGroups"),
      current = toRef(props, "currentQuestion");

    let type = computed(() => groups.value[current.value - 1].view);
    const prevPage = () => {
      answers = {};
      context.emit("prevPage");
    };
    const nextPage = () => {
      if (
        answers &&
        Object.keys(answers).length ===
          groups.value[current.value - 1]?.questions?.length
      ) {
        context.emit("nextPage", answers);
        answers = {};
      } else {
        notify({
          type: "error",
          title: "Нужно ответить на все вопросы",
        });
      }
    };

    // Получение из дочерних компонентов ответов
    const updateAnswers = (ans) => {
      if (!props.isRequestPending) {
        answers = ans;
      }
    };

    return {
      prevPage,
      nextPage,
      updateAnswers,
      answers,
      groups,
      current,
      type,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-page {
  text-align: left;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;

  &__header {
    padding: 70px 75px 15px;
  }

  &__legend {
    font-size: 18px;
    font-weight: 700;
    color: var(--main-color);
    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
    }
  }

  &__progress {
    margin-top: 10px;
    height: 3px;
    background-color: var(--cream-color);
    border-radius: 1px;
    overflow: hidden;

    .progress-bar {
      background-color: #59d884;
      transition: width 0.6s ease;
      height: 3px;
    }
  }

  &__body {
    padding: 20px 70px;
  }

  &__footer {
    padding: 23px 75px 23px;
    .btn {
      padding: 11px 25px;
    }
  }

  @media (max-width: 700px) {
    &__body {
      padding: 20px 30px;
    }

    &__header {
      padding: 30px 30px 15px;
    }

    &__footer {
      padding: 23px 30px 23px;
    }
  }
}


</style>