<template>
  <div
    v-if="questionGroup"
    class="flex justify-content-center slider-question align-item-center w-full"
  >
    <img
      v-if="questionGroup.image"
      class="slider-question__image"
      :src="`${imageUrl}/${questionGroup.image}`"
      alt="Картинка"
    />
    <div class="w-full slider-question__question">
      <p class="slider-question__description">{{ questionGroup.text }}</p>
      <div v-if="questionGroup.questions">
        <div
          v-for="question in questionGroup.questions"
          :key="question.id"
          class="slider-question__answer flex align-item-center"
        >
          <p v-if="question.text" class="slider-question__text">
            {{ question.text }}
          </p>
          <div class="slider-question__slider slider flex w-full">
            <div
              v-for="(answer, index) in question.answers"
              :key="answer.id"
              class="slider__radio-wrap flex flex-column"
            >
              <label :for="`answer_${question.id}_${answer.id}`">
                <div v-if="!answer.text && index === 0">Низкая степень</div>
                <div
                  v-if="isEmotion && winWidth > 1050 && index > 0 && index < 4"
                ></div>
                <div v-else>{{ answer.text }}</div>
                <div
                  v-if="!answer.text && index === question.answers.length - 1"
                >
                  Высокая степень
                </div>
              </label>
              <input
                :id="`answer_${question.id}_${answer.id}`"
                :name="`answer_${question.id}`"
                type="radio"
                @change="setAnswer"
                :value="answer.value"
                :data-answer="answer.id"
                :data-question="question.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, toRef, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import useAnswers from "@/mixins/useAnswers";

export default {
  name: "table-question-group",
  props: {
    questionGroup: {
      type: Object,
    },
    missedGroup: {
      type: Number,
      default: 0,
    }
  },
  inject: ["imageUrl"],
  setup(props, context) {
    const group = toRef(props, "questionGroup");
    const missedGroup = toRef(props, "missedGroup");
    const { setAnswer, clearAnswers } = useAnswers(context);

    const store = useStore();
    const winWidth = computed(() => store.state.width);
    // Проверка на то что вопрос - шкала
    const isEmotion = computed(
      () => group.value.questions[0].answers[1].text == "Слабо"
    );

    onBeforeMount(() => {
      // Если это один из вопросов с незаполненными ответами - дополняем тремя промежуточными значениями
      group.value.questions.forEach((question) => {
        if (question.answers.length === 5 && question.answers[1].text === "") {
          question.answers[1].text = "Слабо";
          question.answers[2].text = "Средне";
          question.answers[3].text = "Выше среднего";
        }
      });
    });

    watch(group, () => {
      clearAnswers();
    });

    watch(missedGroup, () => {
      if (missedGroup.value === group.value.id) {
        clearAnswers();
      }
    });

    return {
      isEmotion,
      winWidth,
      group,
      setAnswer,
    };
  },
};
</script>

<style lang="scss" scoped>
.slider-question {
  &__image {
    display: block;
    max-width: 500px;
    max-height: 500px;
    margin-bottom: 15px;
    border-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
    flex: 1;
    margin-right: 50px;
  }

  &__answer {
    padding: 20px 40px;
    width: 100%;
    border: 1px solid #ececee;
    border-radius: 4px;
    margin-top: 20px;

    p {
      padding-right: 20px;
      flex: 0 1 30%;
    }
  }

  &__description {
    font-size: 20px;
  }

  @media (max-width: 1500px) {
    flex-direction: column;
    &__image {
      max-width: 90%;
      max-height: 300px;
      margin-right: 0;
    }
    &__description {
      text-align: center;
    }
  }

  @media (max-width: 1285px) {
    &__answer {
      padding: 20px 20px;
    }
  }

  @media (max-width: 1050px) {
    &__answer {
      flex-direction: column;
    }

    &__slider {
      flex-direction: column;
      margin-top: 8px;
    }
  }

  @media (max-width: 852px) {
    &__image {
      max-width: 90%;
    }
    &__description {
      font-size: 17px;
    }
  }
}

.slider {
  &__radio-wrap {
    flex: 1;
    position: relative;
    label {
      display: block;
      padding: 0 5px 10px 5px;
      text-align: center;
      flex: 1 0 auto;
      word-break: break-word;
      word-break: keep-all;
      max-width: 93%;
      margin: auto;
      font-size: 14px;
    }

    &:not(:last-child)::before {
      content: "";
      position: absolute;
      height: 3px;
      width: 50%;
      display: block;
      background-color: var(--cream-color);
      bottom: 12px;
      left: 50%;
    }
    &:not(:first-child)::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 50%;
      display: block;
      background-color: var(--cream-color);
      bottom: 12px;
      right: 50%;
    }
    input {
      width: 24px;
      height: 24px;
      display: block;
      background-color: #ffffff;
      border: 3px solid var(--cream-color);
      border-radius: 50%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      z-index: 1;
      cursor: pointer;
      margin: auto;
    }

    input:hover,
    input:focus {
      border-color: var(--main-color) !important;
    }

    input:checked {
      border-color: var(--main-color) !important;
      background-color: var(--main-color) !important;
      transition: all 0.2s ease-out;
    }
  }


  @media (max-width: 1420px) {
    label {
      font-size: 14px;
    }
  }

  @media (max-width: 1050px) {
    &__radio-wrap {
      flex-direction: row;
      border-bottom: 1px solid var(--cream-color);
      padding: 10px 0;

      &::before,
      &::after {
        display: none;
        opacity: 0;
      }
    }

    label {
      font-size: 16px;
      text-align: left;
      padding-bottom: 0;
    }
  }
}
</style>