<template>
  <div
    v-if="questionGroup"
    class="flex justify-content-between icon-question align-item-center w-full"
  >
    <img
      v-if="questionGroup.image"
      class="icon-question__image"
      :src="`${imageUrl}/${questionGroup.image}`"
      alt="Картинка"
    />
    <div class="icon-question__question">
      <p class="icon-question__description">{{ questionGroup.text }}</p>
      <div v-if="questionGroup.questions" class="w-full">
        <div
          v-for="question in questionGroup.questions"
          :key="question.id"
          class="
            icon-question__answer
            flex
            align-item-center
            justify-content-between
          "
        >
          <p v-if="question.text" class="icon-question__text">
            {{ question.text }}
          </p>
          <div class="icon-question__slider justify-content-around slider flex">
            <div
              v-for="(answer, index) in question.answers"
              :key="answer.id"
              class="slider__radio-wrap flex flex-column"
            >
              <input
                :id="`answer_${question.id}_${answer.id}`"
                :name="`answer_${question.id}`"
                type="radio"
                tabindex="1"
                @change="setAnswer"
                :value="answer.value"
                :data-answer="answer.id"
                :data-question="question.id"
              />
              <label
                :style="`background-image: url(${require(`@/assets/images/test-icons/${
                  index === 0 ? 'neutral' : `${emotions[question.text]}${index}`
                }.jpg`)})`"
                :for="`answer_${question.id}_${answer.id}`"
              ></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef, watch } from "@vue/runtime-core";
import useAnswers from "@/mixins/useAnswers";
import emotionsTable from "@/mixins/dictionaries/emotionIconsTable";
export default {
  name: "icons-question-group",
  props: {
    questionGroup: {
      type: Object,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    missedGroup: {
      type: Number,
      default: 0,
    }
  },
  inject: ["imageUrl"],
  setup(props, context) {
    const group = toRef(props, "questionGroup");
    const missedGroup = toRef(props, "missedGroup");
    const { setAnswer, clearAnswers } = useAnswers(context);
    const emotions = emotionsTable;

    watch(group, () => {
      clearAnswers();
    });

    watch(missedGroup, () => {
      if (missedGroup.value === group.value.id) {
        clearAnswers();
      }
    });

    return {
      setAnswer,
      emotions,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon-question {
  &__image {
    display: block;
    max-width: 900px;
    max-height: 500px;
    margin-bottom: 15px;
    border-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
    margin-right: 50px;
    flex: 1;
  }

  &__description {
    font-size: 20px;
  }

  &__answer {
    padding: 20px 40px;
    width: 100%;
    border: 1px solid #ececee;
    border-radius: 4px;
    margin-top: 20px;

    p {
      padding-right: 20px;
      flex: 1 0 15%;
    }
  }
  &__slider {
    flex: 1 1 auto;
  }
  &__question {
    flex: 1 1 50%;
  }

  @media (max-width: 1500px) {
    flex-direction: column;
    &__image {
      max-width: 90%;
      max-height: 300px;
      margin-right: 0;
    }
    // &__answer {
    //   p {
    //     flex: 1 0 auto;
    //   }
    // }
    &__question {
      width: 100%;
      p {
        text-align: center;
      }
    }
  }

  @media (max-width: 1285px) {
    &__answer {
      padding: 20px 20px;
    }
  }

  @media (max-width: 1050px) {
    &__answer {
      flex-direction: column;
      p {
        flex: 1 1 auto;
        padding: 10px 0;
      }
    }

    &__slider {
      // flex-direction: column;
      flex-wrap: wrap;
      margin-top: 8px;
    }
  }

  @media (max-width: 600px) {
    &__answer {
      padding: 10px;
    }

    &__description {
      font-size: 17px;
    }
  }
}

.slider {
  &__radio-wrap {
    position: relative;
    label {
      display: inline-block;
      height: 60px;
      width: 60px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      cursor: pointer;
      background-blend-mode: multiply;
    }
    label:hover {
      background-color: rgba(94, 230, 135, 0.1);
    }

    input {
      display: none;
    }
  }

  &__radio-wrap input:checked ~ label {
    background-color: rgba(94, 230, 135, 0.3);
  }

  &__radio-wrap input:focus ~ label {
    background-color: rgba(94, 230, 135, 0.1);
  }

  @media (max-width: 1050px) {
    &__radio-wrap {
      flex-direction: row;
      margin: 3px;
    }

    label {
      padding-bottom: 0;
      height: 50px;
      width: 50px;
    }
  }

  @media (max-width: 767px) {
    &__radio-wrap {
      margin: 0;
    }
  }
}
</style>